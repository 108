@use 'src/_reece.scss';

.select-input {
  font-family: Roboto;

  &__wrapper {
    color: reece.$dark-text-gray-100;
    font-size: 1rem;
    line-height: 1.5rem;
    position: relative;
  }
  &__label {
    display: block;
    margin-bottom: 0.5rem;
    width: max-content;
  }
  &__required-star {
    color: reece.$orangy-red-100;
  }
  &__button {
    position: relative;
    color: reece.$dark-text-gray-100;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    border-radius: 0.25rem;
    padding: 0.6875rem 3.5rem 0.6875rem 1rem; // accounts for 2px added by the border
    background-color: reece.$white-100;
    border: 1px solid reece.$medium-gray-100;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: left;
    height: 3rem;
    &:focus,
    &:hover,
    &.open {
      &:not(:disabled) {
        border-color: reece.$light-blue-100;
      }
    }
    &:focus,
    &.open {
      outline: none;
      box-shadow: 0 0 4px rgba(reece.$light-blue-100, 0.5);
    }
  }
  &__button-icon {
    position: absolute;
    height: 100%;
    width: 1.5rem;
    right: 1.5rem;
    top: 0;
    color: reece.$dark-blue-100;
  }
  &__options {
    position: absolute;
    top: 100%;
    margin-top: 0.5rem;
    z-index: 99;
    background-color: reece.$white-100;
    width: 100%;
    border-radius: 4px;
    border: 1px solid reece.$medium-gray-100;
    padding: 1rem 0;
    max-height: 15rem;
    overflow-y: auto;
    list-style: none;
  }
  &__option {
    display: flex;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    align-items: center;
    cursor: pointer;
    &__arrow {
      margin-left: auto;
      height: 0.75rem;
      margin-right: 1rem;
      color: reece.$dark-blue-100;
      opacity: 0;
    }

    &:hover,
    &.active {
      background-color: reece.$light-blue-10;
      .select-input__option__arrow {
        opacity: 1;
      }
    }
  }
  &__message-wrapper {
    padding: 0.25rem 1rem 0;
    font-size: 0.8rem;
    line-height: 1rem;
  }

  &.open {
    .select-input__button-icon {
      transform: rotate(180deg);
    }
  }

  &.disabled {
    .select-input__button-icon {
      color: reece.$medium-gray-100;
    }
  }
  &.error {
    .select-input__button {
      border-color: reece.$orangy-red-100;
      &:focus,
      &.open {
        box-shadow: 0 0 4px rgba(reece.$orangy-red-100, 0.5);
      }
    }
    .select-input__button-icon {
      color: reece.$orangy-red-100;
    }
    .select-input__message-wrapper {
      color: reece.$orangy-red-100;
    }
  }
}
