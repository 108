@use 'src/_reece.scss';

.masked-input {
  font-family: Roboto;
  color: reece.$dark-text-gray-100;
  font-size: 1rem;
  line-height: 1.5rem;
  width: 100%;
  &__label {
    display: block;
    margin-bottom: 0.5rem;
    width: max-content;
  }
  &__required-star {
    color: reece.$orangy-red-100;
  }
  &__input {
    color: reece.$dark-text-gray-100;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    border-radius: 0.25rem;
    padding: 0.6875rem 1rem 0.6875rem; // accounts for 2px added by the border
    background-color: reece.$white-100;
    border: 1px solid reece.$medium-gray-100;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    ::placeholder {
      color: reece.$medium-gray-100;
    }
    &:hover,
    &:focus {
      &:not(:disabled) {
        border-color: reece.$light-blue-100;
      }
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 4px rgba(reece.$light-blue-100, 0.5);
    }
  }
  &__message-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 1rem 0;
    font-size: 0.8rem;
    line-height: 1rem;
  }

  &.error {
    .masked-input__input {
      border-color: reece.$orangy-red-100;
      &:focus {
        box-shadow: 0 0 4px rgba(reece.$orangy-red-100, 0.5);
      }
    }
    .masked-input__input-icon {
      color: reece.$orangy-red-100;
    }
    .masked-input__message-wrapper {
      color: reece.$orangy-red-100;
    }
  }
}
