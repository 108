@use 'src/_reece.scss';

$button-hover-blue: #024e8e;

.button {
  font-family: Roboto;
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  width: fit-content;
  border-radius: 2px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  cursor: pointer;
  white-space: nowrap;

  svg {
    height: 1.25rem;
    width: 1.25rem;
  }

  &:disabled {
    cursor: auto;
  }

  // sizes
  &.default {
    height: 2.5rem;
    padding: 0 1.5rem;
    &.icon {
      padding: 0;
      width: 2.5rem;
    }
  }
  &.small {
    height: 1.875rem;
    padding: 0 1rem;
    font-size: 0.875rem;
    line-height: 1.375rem;
    &.icon {
      width: 1.875rem;
      padding: 0;
    }
  }
  &.large {
    height: 3.5rem;
    padding: 0 2rem;
    &.icon {
      width: 3.5rem;
      padding: 0;
    }
  }
  &.x-small {
    height: 0.938rem;
    padding: 0 1rem;
    font-size: 0.8rem;
    line-height: 0.938rem;
    &.icon {
      width: 1.5rem;
      padding: 0;
    }
  }

  // variants
  &.primary {
    background-color: reece.$dark-blue-100;
    color: reece.$white-100;
    &:hover,
    &:focus {
      background-color: $button-hover-blue;
    }
    &:disabled {
      background-color: reece.$medium-gray-100;
    }
  }
  &.secondary {
    background-color: transparent;
    color: reece.$dark-blue-100;
    border: 1px solid reece.$dark-blue-100;
    &:disabled {
      color: reece.$text-gray-60;
      border-color: reece.$text-gray-60;
    }
  }
  &.alternative {
    background-color: reece.$light-blue-100;
    color: reece.$white-100;
    &:hover,
    &:focus {
      background-color: $button-hover-blue;
    }
    &:disabled {
      background-color: reece.$medium-gray-100;
    }
  }
  &.text-link {
    text-decoration: underline;
    color: reece.$light-blue-100;
    background-color: transparent;
    &:hover,
    &:focus {
      color: $button-hover-blue;
    }
    &:disabled {
      color: reece.$medium-gray-100;
    }
  }
  &.text-link-dark {
    color: reece.$dark-blue-100;
    background-color: transparent;
    &:hover,
    &:focus {
      color: $button-hover-blue;
    }
    &:disabled {
      color: reece.$medium-gray-100;
    }
  }
  &.icon {
    color: reece.$dark-blue-100;
    background-color: transparent;
    border-radius: 99px;
    &:hover,
    &:focus {
      background-color: reece.$light-gray-100;
    }
    &:disabled {
      background-color: transparent;
      color: reece.$medium-gray-100;
    }

    &.default {
      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
    &.small {
      svg {
        height: 1rem;
        width: 1rem;
      }
    }
    &.large {
      svg {
        height: 2rem;
        width: 2rem;
      }
    }
  }
}
