@use 'src/_reece.scss';

.checkbox-input {
  font-family: Roboto;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: none;
  background: none;
  height: 1.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: reece.$dark-text-gray-100;
  padding: 0;

  &__icon-wrapper {
    padding: 0.125rem;
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    height: 1.5rem;
    width: 1.5rem;
  }

  &:not(.disabled) {
    .checkbox-input__icon {
      path {
        fill: reece.$light-blue-100;
        fill-opacity: 1;
      }
    }
  }

  &:hover:not(.disabled) {
    .checkbox-input__icon {
      path {
        fill: reece.$light-blue-100;
        fill-opacity: 1;
      }
    }
  }

  &:active:not(.disabled) {
    .checkbox-input__icon-wrapper {
      background-color: rgba(0, 121, 217, 0.2);
    }
  }
}
