@use 'src/_reece.scss';

.stepper {
  font-family: Roboto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  &__step-button {
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: 4rem;
    &.completed {
      .stepper__step-bubble {
        background-color: reece.$light-blue-100;
      }
    }
    &.active {
      .stepper__step-bubble {
        box-shadow: 0px 0px 0px 6px rgba(reece.$light-blue-100, 0.3);
      }
    }
  }
  &__step-bubble {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 1.5rem;
    background-color: reece.$text-gray-20;
    z-index: 9;
  }
  &__step-text {
    margin: 0.75rem 0 0;
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: center;
  }

  &__step-bar {
    z-index: 8;
    content: ' ';
    height: 0.5rem;
    background-color: reece.$text-gray-20;
    flex-grow: 1;
    margin: 0.5rem -2rem;
    &.completed {
      background-color: reece.$light-blue-100;
    }
    &:first-of-type {
      display: none;
    }
  }
}
