@use 'src/_reece.scss';

.advanced-tooltip {
  &__typography {
    &__default {
      font-weight: normal;
    }
    &__bold {
      font-weight: bold;
    }
  }

  &__grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &.mobile {
      gap: 0.5rem;
      &__icon {
        width: 10%;
      }
      &__text {
        width: 90%;
      }
    }
    &.desktop {
      gap: 0.4rem;
      &__icon {
        width: 10%;
      }
      &__text {
        width: 90%;
      }
    }
  }
}
