// ---------- //
// Colors
// ---------- //
//black
$black-100: #000;
// white
$white-100: #ffffff;

// off-white
$off-white-100: #f5f1ed;

// dark blue
$dark-blue-100: #003766;
$dark-blue-90: #1a4b76;
$dark-blue-80: #335f85;
$dark-blue-70: #4d7394;
$dark-blue-60: #6687a3;
$dark-blue-50: #7f9ab2;
$dark-blue-40: #99afc2;
$dark-blue-30: #b3c3d2;
$dark-blue-20: #ccd7e0;
$dark-blue-10: #e6ebf0;

// light blue
$light-blue-100: #0b66ec;
$light-blue-90: #2476ee;
$light-blue-80: #3c85f0;
$light-blue-70: #5594f2;
$light-blue-60: #6da3f4;
$light-blue-50: #84b2f5;
$light-blue-40: #9dc2f7;
$light-blue-30: #b6d2fa;
$light-blue-20: #cee0fb;
$light-blue-10: #e7f0fe;
$light-blue-5: #f4f9ff;

// dark text gray
$dark-text-gray-100: #404040;
$dark-text-gray-90: #545454;
$dark-text-gray-80: #666666;
$dark-text-gray-70: #7a7a7a;
$dark-text-gray-60: #8c8c8c;
$dark-text-gray-50: #9f9f9f;
$dark-text-gray-40: #b3b3b3;
$dark-text-gray-30: #c6c6c6;
$dark-text-gray-20: #d9d9d9;
$dark-text-gray-10: #ececec;

// orange
$orange-100: #ffa500;
$orange-90: #ffae1a;
$orange-80: #ffb733;
$orange-70: #ffc04d;
$orange-60: #ffc966;
$orange-50: #ffd17f;
$orange-40: #ffdb99;
$orange-30: #ffe4b3;
$orange-20: #ffedcc;
$orange-10: #fff6e6;

// text-gray
$text-gray-100: #606060;
$text-gray-90: #717171;
$text-gray-80: #808080;
$text-gray-70: #909090;
$text-gray-60: #a0a0a0;
$text-gray-50: #afafaf;
$text-gray-40: #c0c0c0;
$text-gray-30: #d0d0d0;
$text-gray-20: #dfdfdf;
$text-gray-10: #f0f0f0;

// medium gray
$medium-gray-100: #cbcbcb;
$medium-gray-90: #d1d1d1;
$medium-gray-80: #d5d5d5;
$medium-gray-70: #dbdbdb;
$medium-gray-60: #e0e0e0;
$medium-gray-50: #e4e4e4;
$medium-gray-40: #eaeaea;
$medium-gray-30: #f0f0f0;
$medium-gray-20: #f5f5f5;
$medium-gray-10: #fafafa;

// light gray
$light-gray-100: #f2f2f2;
$light-gray-90: #f4f4f4;
$light-gray-80: #f5f5f5;
$light-gray-70: #f6f6f6;
$light-gray-60: #f7f7f7;
$light-gray-50: #f8f8f8;
$light-gray-40: #fafafa;
$light-gray-30: #fcfcfc;
$light-gray-20: #fdfdfd;
$light-gray-10: #fefefe;

// orangy-red
$orangy-red-100: #c82d15;
$orangy-red-90: #ce422d;
$orangy-red-80: #d35744;
$orangy-red-70: #d96c5c;
$orangy-red-60: #de8173;
$orangy-red-50: #e39589;
$orangy-red-40: #e9aba1;
$orangy-red-30: #efc0b9;
$orangy-red-20: #f4d5d0;
$orangy-red-10: #faeae8;

// green
$green-100: #407a26;
$green-90: #54883c;
$green-80: #669551;
$green-70: #7aa268;
$green-60: #8caf7d;
$green-50: #9fbc92;
$green-40: #b3caa8;
$green-30: #c6d8be;
$green-20: #d9e4d4;
$green-10: #ecf2e9;

// yellow
$yellow-100: #9d6601;
$yellow-90: #a7761b;
$yellow-80: #b18534;
$yellow-70: #bb944e;
$yellow-60: #c4a367;
$yellow-50: #cdb27f;
$yellow-40: #d8c299;
$yellow-30: #e2d2b3;
$yellow-20: #ebe0cc;
$yellow-10: #f6f0e6;

// teal
$teal-100: #3ddbcc;
$teal-90: #51dfd2;
$teal-80: #64e2d6;
$teal-70: #78e6dc;
$teal-60: #8be9e0;
$teal-50: #9dece5;
$teal-40: #b1f1eb;
$teal-30: #c5f5f0;
$teal-20: #d8f8f5;
$teal-10: #ecfcfa;

// sky blue
$sky-blue-100: #44c7f4;
$sky-blue-90: #57cdf6;
$sky-blue-80: #69d2f6;
$sky-blue-70: #7dd8f8;
$sky-blue-60: #8fddf8;
$sky-blue-50: #a1e2f9;
$sky-blue-40: #b4e9fb;
$sky-blue-30: #c7effc;
$sky-blue-20: #daf4fd;
$sky-blue-10: #edfafe;

// purple
$purple-100: #8c44ef;
$purple-90: #9857f1;
$purple-80: #a369f2;
$purple-70: #af7df4;
$purple-60: #ba8ff5;
$purple-50: #c5a1f6;
$purple-40: #d1b4f9;
$purple-30: #ddc7fb;
$purple-20: #e8dafc;
$purple-10: #f4edfe;

// ---------- //
// Breakpoints
// ---------- //
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;
